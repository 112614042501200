import {
    request
} from '@/utils/request.js'

const login = (data, callback, errCallback) => {
    request({
        url: '/v1/auth/login',
        data: data,
        noToken: true,
        method: 'POST',
        success: callback,
        fail: errCallback,
    })
}

const getUserInfo = (callback, errCallback) => {
    request({
        url: '/v1/auth/user_info',
        method: 'GET',
        success: callback,
        fail: errCallback,
    })
}

export {
    login,
    getUserInfo
}

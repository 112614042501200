<template>
  <div class="header">
    <img class="logo" src="../../static/logo.png" alt=""/>
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Monitor',
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  height: 65px;
  width: 100%;
  background-color: black;
  //padding: 30px 50px 0 50px;
  justify-content: flex-start;
  align-items: center;

  .logo {
    width: 35px;
    padding: 0 50px 0 100px;
  }


  .title {
    margin: auto;
    font-size: 22px;
    color: white;
  }
}
</style>

<template>
	<div class="login-container">
		<el-card class="login-card">
			<div slot="header" class="clearfix"><span>Login</span></div>
			<div class="login-form">
				<el-form
					ref="form"
					:model="form"
					:rules="rules"
					label-width="80px"
				>
					<el-form-item label="User:">
						<el-input
							v-model="form.user"
						></el-input>
					</el-form-item>
					<el-form-item label="Password:">
						<el-input
							type="password"
							v-model="form.password"
							show-password
						></el-input>
					</el-form-item>
				</el-form>
				<div class="login-button">
					<el-button type="primary" size="mini" @click="onSubmit">
						Login
					</el-button>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
import { login } from '@/servers/auth.js';

export default {
	data() {
		return {
			form: {
				user: '',
				password: '',
			},
			rules: {
				user: [
					{
						required: true,
						message: 'please input user',
						trigger: 'blur',
					},
				],
				password: [
					{
						required: true,
						message: 'please input password',
						trigger: 'blur',
					},
				],
			},
		};
	},
	methods: {
		onSubmit: function() {
			let that = this;
			this.$refs.form.validate((valid) => {
				if (valid) {
					login(that.form, function(res) {
						sessionStorage.setItem('token', res.access_token);
						that.$router.replace({
							name: 'monitor',
						});
					});
				} else {
					that.$message.warning('please check your input.');
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.login-container {
	display: flex;
	width: 100vw;
	height: 90vh;
	justify-content: center;
	align-items: center;

	.login-card {
		width: 480px;

		.login-button {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 30px;
		}
	}

	.login-form {
		padding: 10px;

		.el-select {
			width: 100%;
		}
	}
}
</style>

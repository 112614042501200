<template>
	<div>
		<div class="padding-percent-10px-5">
			<div class="title">
				<p class="text-left flex-2">
					<b>{{ monitorData.config.label }}</b>
				</p>
				<p class="font-lightgray flex-1">
					{{
						monitorData.now != null
							? monitorData.now.add_date.split('.')[0]
							: getNewTime(monitorData)
					}}
				</p>
				<p
					class="font-status flex-1 text-right"
					:class="
						getNowStatus(monitorData)
							? getColor(getNowStatus(monitorData))
							: getColor(monitorData)
					"
				>
					{{
						getNowStatus(monitorData)
							? getString(getNowStatus(monitorData))
							: getString(monitorData)
					}}
				</p>
			</div>

			<div
				:class="
					'availability-time-line-graphic ' +
						getLineGraphicColor(item.status)
				"
				v-for="(item, i) in monitorData.records"
				:key="i"
				@click="getData(item, i)"
				v-popover:popover="'popover' + i"
			>
				<span :class="i === triangle_i ? 'triangle' : ''"></span>
				<el-popover
					:ref="'popover' + i"
					placement="bottom"
					:title="getString(item.status)"
					width="200"
					trigger="hover"
					:close-delay="0"
					:content="item.add_date.split('.')[0]"
				></el-popover>
			</div>

			<div class="title">
				<p class="font-lightgray-left">Last 90 Times</p>
				<el-divider direction="horizontal"></el-divider>
				<p class="font-lightgray-right">Now</p>
			</div>
		</div>

		<div class="container-card" v-show="show_switch">
			<div
				class="icon-down"
				@click="
					show_switch = !show_switch;
					triangle_i = '';
				"
			>
				<i class="el-icon-arrow-down"></i>
			</div>

			<el-card class="monitor-card">
				<b>{{ getDate(show_content.add_date) }}</b>

				<p class="font-color margin-top-20">{{ show_content.info }}</p>

				<b class="margin-top-20">Status:</b>
				<b :class="getColor(show_content)">
					{{ getString(show_content) }}
				</b>
			</el-card>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		monitorData: {
			type: Object,
			default: function() {
				return {};
			},
		},
	},

	data() {
		return {
			show_content: {},
			show_switch: false,
			triangle_i: '',
		};
	},
  watch: {
    monitorData() {
      this.show_switch = false;
      this.triangle_i = '';
    }
  },
	methods: {
		getNewTime(data) {
			if (data.records) {
				if (data.records.length > 0) {
					return data.records[data.records.length - 1].add_date.split(
						'.',
					)[0];
				}
			}
		},

		getString(status) {
			status = this.statusJudge(status);
			switch (status) {
				case 0:
					return 'Succeed';
				case 1:
					return 'Failed';
				case 2:
					return 'Warning';
				case 3:
					return 'Running';
			}
		},
		statusJudge(status) {
			if (status['status'] !== undefined) {
				status = status.status;
			} else if (typeof status == 'number') {
				return status;
			} else {
				if (status['records'] !== undefined) {
					if (status.records.length > 0) {
						status =
							status.records[status.records.length - 1].status;
					}
				}
			}
			return status;
		},

		getNowStatus(v) {
			if (v['now'] == null) {
				return false;
			} else {
				return { status: v.now.status };
			}
		},
		getColor(status) {
			status = this.statusJudge(status);

			switch (status) {
				case 0:
					return 'succeed-color';
				case 1:
					return 'failed-color';
				case 2:
					return 'warning-color';
				case 3:
					return 'running-color';
			}
		},
		getDate(val) {
			if (val) {
				let val1 = val.split(' ');
				let val2 = new Date(val).toDateString().split(' ');
				return (
					val1[1].split('.')[0] +
					' ' +
					val2[2] +
					' ' +
					val2[1] +
					' ' +
					val2[3]
				);
			}
		},
		getData(val, i) {
			this.show_switch = true;
			this.show_content = val;
			this.triangle_i = i;
		},

		getLineGraphicColor(status) {
			switch (status) {
				case 0:
					return 'succeed-background-color';
				case 1:
					return 'failed-background-color';
				case 2:
					return 'warning-background-color';
				case 3:
					return 'running-background-color';
			}
		},
	},
};
</script>

<style scoped lang="scss">
.triangle {
	display: block;
	margin-top: 37px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent #409eff transparent;
}

.title {
	display: flex;
	align-items: center;
	font-size: 16px;
	height: 30px;

	.font-status {
		color: #52d48a;
	}
}

.icon-down {
	text-align: right;
	margin: -10px 0 10px 0;
}

.container-card {
	padding: 0 100px 0 100px;
	justify-content: center;
	font-size: 14px;

	.monitor-card {
		max-height: 300px;
		white-space: pre-wrap;
		overflow-y: auto;
		padding: 0 100px 0 100px;
	}
}

.font-lightgray-left {
	font-size: 14px;
	color: #606266;
	width: 127px;
	margin-right: 20px;
}

.font-color {
	font-size: 14px;
	color: #606266;
}

.font-lightgray-right {
	font-size: 14px;
	color: #606266;
	margin-left: 20px;
}

.margin-right-20 {
	margin-right: 20px;
}

.margin-left-20 {
	margin-left: 10px;
}

.availability-time-line-graphic {
	display: inline-block;
	height: 40px;
	width: 10px;
	margin: 0 0 5px 5px;
	background-color: #2dcb71;
}

.availability-time-line-graphic:hover {
	background-color: lightgray !important;
}

.succeed-color {
	color: #2dcb71;
}

.failed-color {
	color: red !important;
}

.warning-color {
	color: orange !important;
}

.running-color {
	color: #2dcb71 !important;
}

.succeed-background-color {
	background-color: #2dcb71;
}

.failed-background-color {
	background-color: red;
}

.warning-background-color {
	background-color: orange;
}

.running-background-color {
	background-color: #2dcb71;
}
</style>

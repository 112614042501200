import {
  Loading,
  Message
} from 'element-ui'

var loadingNum = 0
var loadingInstance = null

const startLoading = function() {
  loadingInstance = Loading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0,0,0,0.2)',
    fullscreen: true
  })
  loadingNum++
}

const closeLoading = function() {
  if (--loadingNum <= 0 && loadingInstance) {
    loadingInstance.close()
  }
}

const showErrorMsg = function(errMsg) {
  Message({
    message: errMsg,
    type: 'error',
    duration: 2000,
    showClose: true
  })
}

const showSuccessMsg = function(msg) {
  Message({
    message: msg,
    type: 'success',
    duration: 1500,
    showClose: true
  })
}

export {
  startLoading,
  closeLoading,
  showErrorMsg,
  showSuccessMsg
}

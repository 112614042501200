import {
  getUserInfo
} from '@/servers/auth.js'


const state = () => ({
  userInfo: {
    userId: '',
    usertype: ''
  }
})


// actions
const actions = {

}

let client_user_list = [
  "asx_client@eigen.capital"
]
let broker_user_list = [
  "asx_broker@eigen.capital"
]
// mutations
const mutations = {
  getLoginedUserInfo: function(state) {
    getUserInfo(function(res) {
      state.userInfo.userId = res

      if (client_user_list.indexOf(res) > -1){
        state.userInfo.usertype = "client"
      }else if(broker_user_list.indexOf(res) > -1){
        state.userInfo.usertype = "broker"
      }

    })
  }
}

export default {
  state,
  actions,
  mutations
}

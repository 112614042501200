import {
    baseUrl,
    errCode
} from './consts.js'

import {
    showErrorMsg,
    startLoading,
    closeLoading
} from './uiUtils.js'

import axios from 'axios'

const service = axios.create({
    baseURL: baseUrl
})

service.interceptors.request.use(config => {
    return config
}, err => {
    Message.error({
        message: '请求出错！'
    })
    return Promise.resolve(err)
})

service.interceptors.response.use(response => {
    return response.data
}, error => {
    return Promise.reject(error)
})

const request = (params) => {
    let noLoading = params.noLoading

    if (!noLoading) {
        startLoading()
    }

    let headers = {}

    let data = params.data
    let requestParams = params.params

    if (params.method == 'GET' && params.data) {
        requestParams = params.data
    }

    if (!params.noToken) {
        headers = Object.assign(headers, {
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        })
    }

    if (params.contentType) {
        headers = Object.assign(headers, {
            'Content-Type': params.contentType
        })
        if (params.contentType.endsWith('form-data') && Object
                .prototype.toString.call(params.data) !==
            '[object FormData]') {
            data = new FormData()
            Object.keys(params.data).forEach(key => {
                data.append(key, params.data[key])
            })
        }
    }

    let requestConfig = {
        method: params.method,
        url: params.url,
        data: data,
        params: requestParams
    }

    if (params.responseType) {
        requestConfig.responseType = params.responseType
    }

    service(Object.assign(requestConfig, {
        headers: headers
    }))
        .then((result) => {
            if (!noLoading) {
                closeLoading()
            }
            if (result.status == 'success') {
                if (params.success) {
                    params.success(result.result)
                }
            } else {
                if (params.fail) {
                    params.fail(result)
                } else {
                    showErrorMsg(result.msg)
                }
            }
        })
        .catch((err) => {

            if (!noLoading) {
                closeLoading()
            }
            if (params.fail) {
                params.fail(err.response.data)
            } else {
                if(err.response.data){
                    showErrorMsg(err.response.data.msg)
                }else {
                    showErrorMsg(err)
                }

            }
        })
}

export {
    request
}

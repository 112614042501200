import {
	request
} from '@/utils/request.js'


const getRecords = (channel, count,data, callback, errCallback) => {
	request({
		url: '/v1/monitor/record/' + channel + '/' + count,
		method: 'GET',
		data:data,
		success: callback,
		fail: errCallback,
	})
}


export {
	getRecords,
}

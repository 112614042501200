import Vue from 'vue'
import App from './App.vue'
import store from './store'
import ElementUI from 'element-ui'
import {
  formatDate
} from '@/utils/datetimeUtils.js'

import 'element-ui/lib/theme-chalk/index.css'

import router from './router'

Vue.use(ElementUI, {
  locale: {
    el: {
      pagination: {
        pagesize: '/page',
        total: `Total {total}`,
        goto: 'Go to',
        pageClassifier: ''

      }
    }
  }
})

Vue.config.productionTip = false
Vue.prototype.$formatDate = formatDate
Vue.prototype.$store = store

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')

import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/pages/login/index.vue'
import Monitor from '@/pages/monitor/index'

Vue.use(Router)

const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

const VueRouterReplace = Router.prototype.replace
Router.prototype.replace = function replace(to) {
    return VueRouterReplace.call(this, to).catch(err => err)
}

let router = new Router({
    routes: [{
        path: '/',
        redirect: {
            name: "login"
        }
    },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/monitor',
            name: 'monitor',
            component: Monitor
        },

        // {
        //   path: '/home',
        //   name: 'home',
        //   component: Home
        // },

        //       {
        //   path: '/server',
        //   name: 'server',
        //   component: Server
        // },
        //       {
        //   path: '/api',
        //   name: 'api',
        //   component: API
        // },
        //       {
        //   path: '/task_scripts',
        //   name: 'taskScripts',
        //   component: TaskScripts
        // },
        //       {
        //   path: '/test_scripts',
        //   name: 'testScripts',
        //   component: TestScripts
        // },

    ]
})

router.beforeEach(function (to, from, next) {
    let token = sessionStorage.getItem('token')
    if (to.name != "login" && !token) {
        router.replace({
            name: 'login',
            query: {
                name: to.name
            }
        })
    } else {
        next()
    }
})

export default router;

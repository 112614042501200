import authConfig from "../../authConfig.json"

const baseUrl = authConfig.audience

const errCode = {
  OK: 200,
  NOT_FOUND: 404,
}

const statusConsts = {
  PENDING: 'pending'
}

export {
  baseUrl,
  errCode,
  statusConsts
}

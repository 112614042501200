<template>
	<div
		v-if="isDataOk"
		class="monitor-item"
		:class="subTabs.length > 1 ? 'tab-muti' : 'tab-singer'"
	>
		<div :class="'tab-item-container-' + subTabs.length">
			<el-tabs v-model="activeName" @tab-click="currentPage = 1; getRecordsData()">
				<div class="search">
					<el-checkbox-group v-model="checkList" @change="currentPage = 1;getRecordsData()">
						<el-checkbox
							label="Succeed"
							v-if="channel != 1"
						></el-checkbox>
            <el-checkbox label="Running"
                v-if="channel == 2"
            ></el-checkbox>
						<el-checkbox label="Failed"></el-checkbox>
						<el-checkbox label="Warning"></el-checkbox>
					</el-checkbox-group>
					<div class="search-input">
						<el-input
							size="small"
							v-model="searchText"
							clearable
							:placeholder="'search ' + searchName + ' name'"
						/>
					</div>
					<div class="margin-right-100">
						<span
							class="refresh"
							@click="
								currentPage = 1;
								getRecordsData();
							"
						>
							<i class="el-icon-refresh-right"></i>
						</span>
					</div>
				</div>
				<el-tab-pane
					:label="item"
					:name="item"
					v-for="(item, index) in subTabs"
					:key="index"
				>
					<div v-if="item == activeName">
						<MonitorItem
							v-for="(data, dataIndex) in monitorData"
							:key="dataIndex"
							:monitorData="data"
						></MonitorItem>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
		<div class="monitor-pagination">
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="currentPage"
				:page-sizes="[10, 20, 50, 100]"
				:page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			></el-pagination>
		</div>
	</div>
</template>

<script>
import { getRecords } from '@/servers/monitor.js';

import MonitorItem from '@/components/monitorItem.vue';
import {showErrorMsg} from "@/utils/uiUtils";

export default {
	components: {
		MonitorItem,
	},

	props: {
		channel: {
			type: Number,
			default: 0,
		},

		count: {
			type: Number,
			default: 90,
		},

		searchName: {
			type: String,
			default: '',
		},

		needInit: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			isDataOk: false,
			monitorData: [],
			activeName: '',
			searchText: '',
			checkList: ['Failed', 'Warning'],
			subTabs: [],
			total: 0,
			currentPage: 1,
			pageSize: 10,
		};
	},

	created() {
		this.getConfigSpace();
		if (this.needInit) {
			this.getRecordsData();
		}
	},

	methods: {
		handleSizeChange(pageSize) {
			this.pageSize = pageSize;
			this.currentPage = 1;
			this.getRecordsData();
		},

		handleCurrentChange(page) {
			this.currentPage = page;
			this.getRecordsData();
		},

		getConfigSpace() {
			if (this.channel == 3) {
				this.subTabs = ['prod_api', 'prod_ui','staging_api',"staging_ui"];
			} else {
				this.subTabs = ['default'];
			}
			this.activeName = this.subTabs[0];
			this.isDataOk = true;
		},

		getRecordsData() {
			let that = this;

			let data = {
				status: that.getRequestData(that.checkList),
				keyword: that.searchText,
				page: that.currentPage,
				size: that.pageSize,
			};
      if (!data.status){
        showErrorMsg("At least one status must be selected.")
        return
      }
			if (this.subTabs.length > 1) {
				data.space = this.activeName;
			}

			getRecords(that.channel, that.count, data, function(res) {
				that.monitorData = res.data;
				that.total = res.total;
			});
		},

		// 装换成请求时需要的参数
		getRequestData(val) {
			let data = [];
			for (let i in val) {
				if (val[i] === 'Succeed') {
					data.push(0);
				} else if (val[i] === 'Failed') {
					data.push(1);
				} else if (val[i] === 'Warning') {
					data.push(2);
				} else if (val[i] === 'Running') {
					data.push(3);
				}
			}
			return data.join(',');
		},
	},
};
</script>

<style lang="scss" scoped>
.monitor-item {
	padding: 0 3%;

	.monitor-pagination {
		text-align: center;
		margin-bottom: 30px;
	}

	/deep/ .el-tabs__item {
		font-size: 12px;
	}

	/deep/ .el-input--suffix .el-input__inner {
		padding-right: 0;
		height: 25px;
	}

	// 修改选择框颜色
	/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		border-color: orange !important;
		background-color: orange !important;
	}

	/deep/ .el-checkbox__inner:hover {
		border-color: orange !important;
	}

	/deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
		border-color: orange !important;
	}

	/deep/ .el-checkbox {
		color: #111;
		margin-right: 30px;
		font-size: 16px;
	}

	// 修改选择框大小
	/deep/ .el-checkbox__inner {
		width: 16px;
		height: 16px;
	}

	// 修改选择label字体颜色
	/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
		color: orange !important;
	}

	// 修改中心对号大小以及位置
	/deep/ .el-checkbox__inner::after {
		left: 4px;
		height: 9px;
		width: 5px;
		top: 0;
	}

	/deep/ .el-input--small .el-input__icon {
		line-height: 26px;
	}
}

.tab-muti {
	@for $i from 1 through 10 {
		.tab-item-container-#{$i} {
			/deep/ .el-tabs__item {
				width: (100 / $i * 1%) !important;
			}
			/deep/ .el-tabs-bar {
				width: (100 / $i * 1%) !important;
			}
			/deep/ .el-tabs__active-bar {
				width: (100 / $i * 1%) !important;
			}
		}
	}
}

.tab-singer {
	/deep/ .el-tabs__header {
		display: none !important;
	}
}
</style>

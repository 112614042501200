<template>
	<div>
		<Header></Header>
		<Container></Container>
	</div>
</template>

<script>
import Header from '@/components/header.vue';
import Container from '@/components/container.vue';

export default {
	components: {
		Header,
		Container,
	},
};
</script>

<template>
	<div class="monitor-container">
		<el-tabs v-model="activeName" @tab-click="flushData">
			<el-tab-pane
				:label="item"
				:name="item"
				v-for="(item, index) in tabs"
				:key="index"
			>
				<MonitorContent
					:ref="item"
					:channel="index"
					:searchName="item"
					:needInit="index == 0"
					v-show="item == activeName"
				></MonitorContent>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import MonitorContent from '@/components/monitorContent.vue';

export default {
	components: {
		MonitorContent,
	},

	data() {
		return {
			tabs: ['Services', 'API State', 'Task Scripts', 'Test Scripts'],
			activeName: 'Services',
		};
	},
	
	methods: {
		flushData: function() {
			if (this.$refs[this.activeName]) {
				this.$refs[this.activeName][0].getRecordsData();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
$tabWidth: 25%;
$activeColor: #ffaa00;

.monitor-container {
	margin-top: 1%;
	/deep/ .el-tabs__nav {
		width: 100%;
	}

	/deep/ .el-tabs__item {
		text-align: center;
		padding: 0;
		width: $tabWidth !important;
		font-size: 20px;
		line-height: 28px;
	}

	/deep/ .el-tabs__item.is-active {
		color: $activeColor !important;
	}

	/deep/ .el-tabs-bar {
		width: $tabWidth !important;
	}

	/deep/ .el-tabs__active-bar {
		background-color: $activeColor !important;
		width: $tabWidth !important;
	}

	/deep/ .el-tabs__item:hover {
		color: $activeColor !important;
	}
}
</style>
